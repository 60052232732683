export default class Form {
  constructor(el) {
    this.root = el;
    this.mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    this.email = this.root.querySelector('.email-field');
    console.log('this.email:',this.email);
    console.log('this.root:',this.root);

    this.email.addEventListener('blur',() => {
      console.log('onblur');
      this.getValidation(this.email, this.mailformat);
    })
  }

  getValidation(el, mailformat) {

    if(el.value.match(mailformat))
      {
        console.log("is valid");
        this.root.querySelector('.cta').disabled = false;

        this.email.classList.contains('form--error') &&
          this.email.classList.remove('form--error')

        return true;
      }
      else
      {
        console.log("error");
        this.email.classList.add('form--error');
        return false;
      }
    }
}
