import "lazysizes";
import Swiper, { Navigation, Pagination, EffectCreative } from "swiper";
import Rellax from "rellax";
import { tns } from "tiny-slider/src/tiny-slider";
import Form from './form';
Swiper.use([Navigation, Pagination, EffectCreative]);

window.addEventListener("DOMContentLoaded", () => {
  document.body.classList.add("show");

  // init slider
  const sliderEl = document.querySelector(".slider");
  const slider = new Swiper(sliderEl, {
    modules: [Navigation, Pagination, EffectCreative],
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    grabCursor: true,
    effect: "creative",
      creativeEffect: {
        prev: {
          shadow: true,
          translate: ["-20%", 0, -1],
        },
        next: {
          translate: ["100%", 0, 0],
        },
      },
    spaceBetween: 0,
    navigation: {
      nextEl: ".slider__btn--next",
      prevEl: ".slider__btn--prev",
    },
    pagination: {
      el: ".swiper-pagination",
      dynamicBullets: true,
    },
  });

  // init project slider
  const fsSliderEl = document.querySelector(".fs-slider__slider");
  const fsSliderControls = document.querySelector(".fs-slider__slider-controls");
  // const fsSlider = tns({
  //   loop: false,
  //   items: 2.3,
  //   container: fsSliderEl,
  //   controlsContainer: fsSliderControls,
  //   gutter: 10,
  //   speed: 400
  // });

  const fsSlider = new Swiper(fsSliderEl, {
    modules: [Navigation],
    slidesPerView: 'auto',
    spaceBetween: 20,
    slidesPerGroup: 1,
    speed: 400,
    navigation: {
      nextEl: ".fs-swiper-button-next",
      prevEl: ".fs-swiper-button-prev",
    },
    pagination: {
      el: ".fs-slider__pagination",
    },
  });
  lazy();

  var rellax = new Rellax('.rellax');
});

// init langSwitch
// const langSwitch = document.querySelector(".header__language");
// const openState = "header__language--is-open";

// function openLang() {
//   langSwitch.classList.add(openState);
//   document.documentElement.addEventListener("click", closeMenuOnBodyClick);
// }

// function closeMenu() {
//   langSwitch.classList.remove(openState);
//   document.documentElement.removeEventListener("click", closeMenuOnBodyClick);
// }

// function closeMenuOnBodyClick(event) {
//   const path = event.composedPath();
//   if (
//     path.some(
//       (elem) => elem.className === "header__language header__language--is-open"
//     )
//   ) {
//     return;
//   }
//   closeMenu();
// }

// langSwitch.addEventListener("click", openLang);

//init wypoint nav

const navItems = document.querySelectorAll(".header__nav-item");

function scrollToId(el) {
  console.log('el',el);
  const target = el.getAttribute("data-scroll-id");
  console.log('target',target);
  const scrollTo = document.getElementById(target).offsetTop;
  scroll({
    top: scrollTo - 110,
    behavior: "smooth",
  });
}

navItems.forEach((navItem) => {
  console.log(navItem);
  navItem.addEventListener("click", () => {
    scrollToId(navItem);
  });
});

const heroStageCta = document.querySelector('.hero-stage__cta');
console.log(heroStageCta)
heroStageCta.addEventListener("click", () => {
  scrollToId(heroStageCta);
});



var lazy = function lazy() {
  document.addEventListener('lazyloaded', function (e)  {
    e.target.parentNode.classList.add('image-loaded');
    // e.target.parentNode.classList.remove('loading');
  });
}

const form = document.querySelector(".contact__form");
  new Form(form);

